import React from "react"

import { Container, Row } from "react-bootstrap"
import SvgLogo from "../SVGs/logo"
import SvgEmail from "../SVGs/Email"
import SvgPillarLogo from "../SVGs/pillarlogo"

const Footer = () => {
  return (
    <footer>
      <Container fluid className="bg-light mt-5">
        <Container className="bg-light">
          <Row className="align-items-center justify-content-between py-3 py-lg-5">
            <div>
              <button
                type="button"
                onClick={() => {
                  document.body.style.overflow = "visible"
                  document.body.scrollTop = 0 // For Safari
                  document.documentElement.scrollTop = 0 // For Chrome, Firefox, IE and Opera
                }}
                className="transparent-button"
                style={{ display: "inline-flex" }}
              >
                <SvgLogo className="logo my-2" />
              </button>
            </div>
            <div className="d-flex align-items-center justify-content-center p-5">
              <p className="mr-3 mb-0 ">A product by</p>
              <a href="https://7thpillar.com/" rel="noreferrer" target="_blank">
                <SvgPillarLogo width="100" />
              </a>
            </div>
            <a
              href="mailto:trashlyft@7thpillar.com"
              className="d-inline-flex align-items-center transparent-button"
            >
              <SvgEmail
                className="mr-2"
                style={{ width: "1.5rem", height: "1.5rem" }}
              />
              <h6
                style={{ fontSize: "1rem" }}
                className="my-2 text-primary font-weight-normal"
              >
                trashlyft@7thpillar.com
              </h6>
            </a>
          </Row>
        </Container>
      </Container>
    </footer>
  )
}

export default Footer
