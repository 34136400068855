import React from "react"
import { StaticQuery, graphql } from "gatsby"

import Header from "./header"
import Footer from "./footer"

const Layout = ({ children, navigationMenu, pageInfo }) => {
  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={() => (
        <>
          <Header
            pageInfo={pageInfo}
            navigationMenu={navigationMenu}
            siteTitle={"7th Pillar"}
          />
          {children}
          <Footer />
        </>
      )}
    />
  )
}
export default Layout
