import React from "react";

const SvgEmail = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="13.88" height="10.41" viewBox="0 0 13.88 10.41" {...props}>
    <path
      id="email"
      d="M12.579,59.882H1.3a1.3,1.3,0,0,0-1.3,1.3v7.807a1.3,1.3,0,0,0,1.3,1.3H12.579a1.3,1.3,0,0,0,1.3-1.3V61.183A1.3,1.3,0,0,0,12.579,59.882Zm0,.867a.431.431,0,0,1,.166.034l-5.8,5.031L1.135,60.783a.432.432,0,0,1,.166-.034Zm0,8.675H1.3a.434.434,0,0,1-.434-.434V61.7l5.788,5.017a.434.434,0,0,0,.568,0L13.012,61.7v7.291A.434.434,0,0,1,12.579,69.424Z"
      transform="translate(0 -59.882)"
    />
  </svg>
);

export default SvgEmail;
