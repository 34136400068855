import React from "react"

import { Navbar, Nav, Container } from "react-bootstrap"
import SvgLogo from "../SVGs/logo"

import scrollTo from "gatsby-plugin-smoothscroll"

const windowGlobal = typeof window !== "undefined" && window

const pages = [
  { to: "#feature_agent", title: "Agent App", eventKey: "feature_agent" },
  {
    to: "#feature_customer",
    title: "Customer App",
    eventKey: "feature_customer",
  },
  { to: "#feature_admin", title: "Admin App", eventKey: "feature_admin" },
  { to: "#contact", title: "Contact", eventKey: "contact" },
]

const Header = ({ pageInfo, navigationMenu }) => {
  React.useEffect(() => {
    let prevScrollpos = windowGlobal.pageYOffset
    const headerElement = document.getElementById("site-navbar")
    windowGlobal.onscroll = () => {
      const currentScrollPos = windowGlobal.pageYOffset
      if (prevScrollpos > currentScrollPos) {
        if (headerElement && headerElement.classList)
          headerElement.classList.remove("hide-site-navbar")
      } else {
        if (currentScrollPos > 80) {
          if (headerElement && headerElement.classList)
            headerElement.classList.add("hide-site-navbar")
        }
      }
      prevScrollpos = currentScrollPos
    }
  }, [])

  const headerHiding = () => {
    const headerElement = document.getElementById("site-navbar")

    if (document.body.style.overflow === "hidden") {
      headerElement.style.position = "sticky"
      document.body.style.overflow = "visible"
      setTimeout(() => {
        headerElement.style.bottom = null
      }, 400)
    } else {
      headerElement.style.position = "fixed"
      headerElement.classList.remove("hide-site-navbar")
      setTimeout(() => {
        headerElement.style.bottom = 0
      }, 400)
      document.body.style.overflow = "hidden"
    }
  }

  const toggleHandBurger = () => {
    headerHiding()
    document.getElementById("hamburger-1").classList.toggle("is-active")
  }

  const toggleMenuItem = item => {
    if (windowGlobal && windowGlobal.innerWidth < 992) {
      headerHiding()
      document.getElementById("hamburger-1").classList.toggle("is-active")
    }

    document.body.style.overflow = "visible"
    setTimeout(() => {
      if (item) scrollTo(item.to)
    }, 300)
  }

  return (
    <>
      <Navbar
        collapseOnSelect
        fixed="top"
        variant="dark"
        expand="lg"
        id="site-navbar"
      >
        <Container>
          <Navbar.Brand
            as="button"
            disabled={
              // document
              //   ?.getElementById("hamburger-1")
              //   ?.className.includes("is-active")
              // &&
              windowGlobal && windowGlobal.innerWidth < 992
            }
            onClick={() => {
              // toggleHandBurger()
              document.body.style.overflow = "visible"
              document.body.scrollTop = 0 // For Safari
              document.documentElement.scrollTop = 0 // For Chrome, Firefox, IE and Opera
            }}
            className="transparent-button"
            style={{ display: "inline-flex" }}
          >
            <SvgLogo className="logo" />
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            id="button-navbar-nav"
            as="button"
            onClick={() => toggleHandBurger()}
          >
            <div className="hamburger" id="hamburger-1">
              <span className="hamburger-line" />
              <span className="hamburger-line" />
              <span className="hamburger-line" />
            </div>
          </Navbar.Toggle>
          <Navbar.Collapse
            className="justify-content-end"
            id="basic-navbar-nav"
          >
            <Nav activeKey={pageInfo && pageInfo.pageName}>
              {pages.map(item => (
                <Nav.Link
                  onSelect={() => {
                    toggleMenuItem(item)
                  }}
                  as="button"
                  eventKey={item.eventKey}
                  className={`${
                    item.eventKey === navigationMenu
                      ? "text-primary"
                      : "text-dark"
                  } transparent-button ml-4 font-weight-bold text-left text-lg-center`}
                >
                  {item.title}
                </Nav.Link>
              ))}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  )
}

export default Header
