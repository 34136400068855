import React from "react"

const SvgLogo = props => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 422 75.301">
    <g
      id="Group_481"
      data-name="Group 481"
      transform="translate(-11.219 -15.695)"
    >
      <g
        id="Group_479"
        data-name="Group 479"
        transform="translate(125.545 25.423)"
      >
        <path
          id="Path_1074"
          data-name="Path 1074"
          d="M168.7,36.533h36.693V47.368H193.815V80.121H180.147V47.368H168.7Z"
          transform="translate(-168.696 -31.17)"
          fill="#2ecc71"
        />
        <path
          id="Path_1075"
          data-name="Path 1075"
          d="M238.124,83.4H224.457V48.68h13.667v6.4c2.709-3.879,6.9-6.649,11.389-6.649V63.025h-3.878c-4.926,0-7.511,1.416-7.511,6.156Z"
          transform="translate(-184.073 -34.452)"
          fill="#2ecc71"
        />
        <path
          id="Path_1076"
          data-name="Path 1076"
          d="M275.945,48.179c4.618,0,7.942,2.031,9.6,5.171V48.61h13.606V83.333H285.549V78.592c-1.662,3.14-4.986,5.172-9.6,5.172-8,0-14.344-6.588-14.344-17.792S267.942,48.179,275.945,48.179ZM280.5,60.122c-2.832,0-5.049,2.032-5.049,5.849s2.216,5.848,5.049,5.848,5.048-2.031,5.048-5.848S283.333,60.122,280.5,60.122Z"
          transform="translate(-194.316 -34.381)"
          fill="#2ecc71"
        />
        <path
          id="Path_1077"
          data-name="Path 1077"
          d="M336.723,83.763c-10.035,0-16.623-5.356-17.238-12.436H332.6c.308,2.093,1.971,3.016,4.064,3.016,1.416,0,2.278-.739,2.278-1.6,0-4.556-17.915-.37-17.915-13.114,0-6.28,4.987-11.451,14.776-11.451,9.912,0,14.775,5.6,15.76,12.436H339.432c-.369-1.847-1.724-2.955-3.941-2.955-1.354,0-2.155.554-2.155,1.6,0,4.31,18.162.308,18.162,13.668C351.5,79.085,345.958,83.763,336.723,83.763Z"
          transform="translate(-210.279 -34.381)"
          fill="#2ecc71"
        />
        <path
          id="Path_1078"
          data-name="Path 1078"
          d="M371.165,33.813h13.667V49.942a12.186,12.186,0,0,1,10.651-5.54c8,0,12.682,5.849,12.682,14.837V79.371H394.559V60.963c0-3.325-1.908-5.356-4.863-5.356-3.017,0-4.864,2.031-4.864,5.356V79.371H371.165Z"
          transform="translate(-224.53 -30.42)"
          fill="#2ecc71"
        />
        <path
          id="Path_1079"
          data-name="Path 1079"
          d="M444.076,36.533V69.778h13.175V80.121H430.409V36.533Z"
          transform="translate(-240.868 -31.17)"
          fill="#2ecc71"
        />
        <path
          id="Path_1080"
          data-name="Path 1080"
          d="M496.145,48.774h15.084L489,100.118H474.044l8.742-18.346-14.468-33h15.207l6.526,17.608Z"
          transform="translate(-251.322 -34.545)"
          fill="#2ecc71"
        />
        <path
          id="Path_1081"
          data-name="Path 1081"
          d="M531.256,54.745h-3.694V43.356h3.694c.123-9.85,7.018-15.145,18.777-14.1V40.894c-3.14-.369-4.555.246-4.987,2.462h4.987V54.745h-5.11V78.079H531.256Z"
          transform="translate(-267.659 -29.128)"
          fill="#2ecc71"
        />
        <path
          id="Path_1082"
          data-name="Path 1082"
          d="M565.021,56.975h-4.309V45.586h4.309V37.213h13.667v8.373h5.664V56.975h-5.664v9.358c0,1.6.739,2.339,2.525,2.339h3.263V80.308H579.3c-8.127,0-14.283-3.447-14.283-14.222Z"
          transform="translate(-276.801 -31.357)"
          fill="#2ecc71"
        />
      </g>
      <g
        id="Group_480"
        data-name="Group 480"
        transform="translate(12.219 15.695)"
      >
        <path
          id="Path_1083"
          data-name="Path 1083"
          d="M91.259,21.247A3.129,3.129,0,0,1,94.5,18.1h13.324a3.128,3.128,0,0,1,3.24,3.151V24.7h2.4V21.247a5.53,5.53,0,0,0-5.641-5.552H94.5a5.531,5.531,0,0,0-5.642,5.552V24.7h2.4Z"
          transform="translate(-33.354 -15.695)"
          fill="#2ecc71"
        />
        <path
          id="Path_1084"
          data-name="Path 1084"
          d="M76.694,35.785h40.571a3,3,0,1,0,0-6H76.694a3,3,0,1,0,0,6Z"
          transform="translate(-29.172 -19.58)"
          fill="#2ecc71"
        />
        <path
          id="Path_1085"
          data-name="Path 1085"
          d="M79.743,39.7,66.457,74.3a5.112,5.112,0,0,0,2.6,6.785l5.083,1.952a8.395,8.395,0,0,1,15.167,4.969c0,.283-.015.563-.042.839l6.294,2.417a5.112,5.112,0,0,0,6.475-3.3l13.285-34.6ZM78.168,75.614a1.2,1.2,0,0,1-2.241-.86L86.093,48.28a1.2,1.2,0,0,1,2.241.86Zm8.264,3.174a1.2,1.2,0,0,1-2.241-.86L94.356,51.453a1.2,1.2,0,0,1,2.242.86ZM94.7,81.961a1.2,1.2,0,1,1-2.241-.86l10.165-26.474a1.2,1.2,0,0,1,2.242.86Z"
          transform="translate(-27.064 -22.315)"
          fill="#2ecc71"
        />
        <line
          id="Line_1"
          data-name="Line 1"
          x1="20.278"
          transform="translate(16.638 13.633)"
          fill="#2ecc71"
          stroke="#2ecc71"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
        />
        <line
          id="Line_2"
          data-name="Line 2"
          x1="29.117"
          transform="translate(0 27.672)"
          fill="#2ecc71"
          stroke="#2ecc71"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
        />
        <line
          id="Line_3"
          data-name="Line 3"
          x1="12.479"
          transform="translate(16.638 43.27)"
          fill="#2ecc71"
          stroke="#2ecc71"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
        />
        <circle
          id="Ellipse_1"
          data-name="Ellipse 1"
          cx="6.794"
          cy="6.794"
          r="6.794"
          transform="translate(47.088 59.501)"
          fill="#2ecc71"
        />
      </g>
    </g>
  </svg>
)

export default SvgLogo
